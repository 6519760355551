import React from 'react'
import MainLayout from '@cmp/site/MainLayout'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import ButtonLink from '@cmp/site/ButtonLink'

const Page404 = () => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <div className='error-page d-flex flex-column justify-content-center align-items-center bg--blue'>
          <h4>404</h4>
          <h1 className='mb-lg-4'>Úps, þessi síða er ekki til.</h1>
          <ButtonLink
            to='/'
            label='Aftur heim'
            white
            className='error-page__btn'
          />
        </div>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page404
