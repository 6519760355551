import React, { useState, useEffect } from 'react'
import qs from 'qs'
import { navigate } from '@reach/router'
import { useQuery } from '@apollo/client'
import { Row, Col, Container, FancyShow } from '@kogk/common'
import { MainLayout, ButtonLink } from '@cmp/site'
import cn from 'classnames'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import styles from './CheckoutThankYou.module.scss'
import { GET_ORDER } from '@root/src/data/backend/queries'
import useQueryParams from '@root/src/hooks/useQueryParams'
import { useCartState } from '@root/src/context/Cart'
import LoadingPage from '@cmp/templates/LoadingPage'
import Page404 from '@cmp/templates/Page404'
import ValidationMessage from '@cmp/site/ValidationMessage'

const CheckoutThankYou = ({
  title,
  message,
  order,
  error
}) => {
  const { t } = useLanguage()
  title = title ?? t('checkout|success-title')
  message = message ?? t('checkout|success-body')

  if (error) {
    return (
      <Container className='mt-2 mt-md-0 pt-4 pt-md-6 mb-md-6'>
        <Row className='flex-column align-items-md-center justify-content-center text-md-center'>
          <ValidationMessage message={error.message} />
        </Row>
      </Container>
    )
  }

  return (
    <Container className='pt-md-4'>
      <FancyShow>
        <Row className='pt-3 mt-1'>
          <Col col={{ md: 6 }} offset={{ md: 1 }}>
            <h1 className='blue'>{title}</h1>
          </Col>
        </Row>
        <Row>
          <Col
            col={{ md: 6 }}
            offset={{ md: 1 }}
            className='pt-2 pt-md-1 pb-1 pb-md-0'
          >
            <div className={cn(styles.text, 'parag--medium')}>{message}</div>
          </Col>
        </Row>
        <Row>
          <Col col={{ md: 3 }} offset={{ md: 1 }} className='pt-3 pb-2'>
            <ButtonLink
              label='Aftur heim'
              to='/'
              large
              bold
              rounded
              className='w-100 d-flex align-items-center justify-content-center'
            />
          </Col>
        </Row>
      </FancyShow>
    </Container>
  )
}

export default props => {
  const { language } = useLanguage()
  const { id, clear, error } = useQueryParams()
  const { clearCart } = useCartState()
  const [pending, setPending] = useState(true)
  const query = useQuery(GET_ORDER, {
    variables: { id },
    pollInterval: pending ? 2000 : 0
  })
  const [threeDsErrorMessage, setThreeDsErrorMessage] = useState("")
  useEffect(() => {
    if(error === 'THREE_DS_FAILED') {
      setThreeDsErrorMessage("3DS Auðkenning mistókst")
    } else if(error === 'ORDER_NOT_FOUND') {
      setThreeDsErrorMessage("Pöntun fannst ekki")
    } else if(error === 'UNEXPECTED_ERROR') {
      setThreeDsErrorMessage("Eitthvað fór úrskeiðis")
    }
  }, [error])
  
  useEffect(() => {
    setPending(query.data?.order?.payment === 'PENDING')
  }, [query.data])

  useEffect(() => {
    if (clear === '' && !pending) {
      clearCart()
      navigate(location.pathname + '?' + qs.stringify({ id }), {
        replace: true
      })
    }
  }, [clear, pending])

  if (query.loading || query.data?.order?.payment === 'PENDING') {
    return <LoadingPage />
  }

  if (!query.error && !query.data.order) {
    return <Page404 />
  }

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <CheckoutThankYou
          {...props}
          error={query.error || threeDsErrorMessage}
          order={query.data?.order}
        />
      </MainLayout>
    </GlobalDataProvider>
  )
}
